import React, { ReactElement } from "react";
import {
    finalShowForm,
    orderListByKeyword,
    replacePlaceholders,
} from "@lib/sharedUtils";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useDomainContext } from "@hooks/useDomainContext";
import { TAffiliateOfferList } from "@page-builder/ts/generated";
import { ClickOffer, ListingItem } from "@lib/shared/types";
import MortgageOffer from "@components/shared/mortgageOffer";
import styles from "./style.module.scss";
const Offer = dynamic(() => import("./Offer"));

export default function AffiliateOffersList({
    props = {},
    inForm = false,
}: {
    props?: TAffiliateOfferList["props"];
    inForm?: boolean;
}): ReactElement {
    const router = useRouter();
    const {
        domain,
        clicksOffers,
        pageBuilderListings,
        visitDetails,
        category,
    } = useDomainContext();
    let offers: ClickOffer[] | ListingItem[] = [];
    const keyword = router.query?.k as string;

    const showOffersInsteadOfForm = !finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );

    switch (props.source) {
        case "auto":
            offers = orderListByKeyword(
                keyword,
                domain?.defaultOffers.filter(
                    (offer) =>
                        offer?.features.length &&
                        offer?.placements?.skipMainOffersList !== true,
                ),
            );
            break;
        case "external":
            if (clicksOffers) {
                offers = clicksOffers;
            } else {
                offers = [];
            }
            break;
        default: {
            if (!props.source) {
                offers = orderListByKeyword(
                    keyword,
                    domain?.defaultOffers.filter(
                        (offer) =>
                            offer?.features.length &&
                            offer?.placements?.skipMainOffersList !== true,
                    ),
                );
                break;
            }
            if (pageBuilderListings && props.source) {
                offers = pageBuilderListings[props.source]?.offers ?? [];
            }
            break;
        }
    }

    if (Array.isArray(offers) && offers.length) {
        return (
            <section>
                {props.title && (
                    <div
                        className={`mb-6 ${styles.sectionTitle}`}
                        dangerouslySetInnerHTML={{
                            __html: replacePlaceholders(props.title, {
                                category: category?.name,
                                region: visitDetails?.region,
                                city: visitDetails?.city,
                            }),
                        }}
                    />
                )}
                <div className={styles.container}>
                    {offers
                        .slice(
                            showOffersInsteadOfForm && !inForm ? 1 : 0,
                            props.maxNumberOfOffers,
                        )
                        ?.map((offer, index) => {
                            const isExternal = !("displayUrl" in offer);
                            const isMortgage = isExternal && offer?.isMortgage;
                            return !isMortgage ? (
                                <Offer
                                    offer={!isExternal ? offer : undefined}
                                    key={index}
                                    index={index}
                                    logoUrl={
                                        isExternal
                                            ? offer.logoUrl
                                            : offer.logo?.fullPath
                                    }
                                    isExternal={isExternal}
                                    buttonText={
                                        isExternal
                                            ? props.buttonText ?? "View Deal"
                                            : offer?.hiddenAttributes
                                                  .buttonText ??
                                              (props.buttonText || "Get Quote")
                                    }
                                    title={
                                        isExternal
                                            ? offer.title
                                            : offer.description
                                    }
                                    bulletedDescription={
                                        isExternal
                                            ? offer.bulletedDescription
                                            : offer.features
                                    }
                                    revenue={
                                        isExternal ? offer.revenue : undefined
                                    }
                                    slug={offer.slug}
                                    featuredOfferMessage={
                                        props.featuredOfferMessage
                                    }
                                    featuredOfferMessagePosition={
                                        props.featuredOfferMessagePosition
                                    }
                                    ratingPosition={props.ratingPosition}
                                    showIndex={props.showIndex}
                                    showRating={props.showRating}
                                    showSummary={props.showSummary}
                                    showSeparator={props.showSeparator}
                                    zone={props.zone}
                                />
                            ) : (
                                <MortgageOffer key={index} offer={offer} />
                            );
                        })}
                </div>
            </section>
        );
    }
    return <></>;
}
